import React from 'react';

function Component({label,name,type,required,extraInputProps}) {

	function onKeyUp(event){
		const input	=	event.target;
		input.value	= input.value.replace(/^(\d{3})(\d{3})(\d{4}).*/, "($1) $2 - $3");
	}

	return (
		<div className="input-group mb-3">
			<div className="input-group-prepend">
				<span
					className="input-group-text"
					id="inputGroup-sizing-default"
				>{label}</span>
			</div>
			<input
				className="form-control"
				aria-label={label}
				aria-describedby={`inputGroup-${label}`}
				name={name}
				required={required}
				type={type??'text'}
				onKeyUp={type==='tel'?onKeyUp:null}
				{...extraInputProps}
			/>
		</div>
	);
}

export default Component;