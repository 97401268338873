import React from 'react';

function Component({label,name,rows,required,optional}) {

	return (
	<div className="input-group mb-3">
		<div className="input-group-prepend">
			<span className="input-group-text">{optional?<span>{label}<br/>(optional)</span>:label}</span>
		</div>
		<textarea name={name} className="form-control" aria-label={label} rows={rows}></textarea>
	</div>
	);
}

export default Component;