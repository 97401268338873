import React from 'react';

//	You are allowed to hide the badge as long as you include the reCAPTCHA branding visibly in the user flow. Please include the following text:
//	https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed

export default function (){

	return (
		<div style={{
			fontWeight	:	'100',
			fontSize	:	'10px',
			opacity		:	'0.6',
		}}>
			<p>
				This site is protected by reCAPTCHA and the Google
					<a href="https://policies.google.com/privacy"> Privacy Policy</a> and
					<a href="https://policies.google.com/terms"> Terms of Service</a> apply.
			</p>
		</div>
	);
}