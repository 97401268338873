import React from 'react';

function Component({label,type,name,value}) {

	return (
		<div className="input-group mb-3">
			<input className="btn btn-primary" type="submit" value={value} name={name} />
		</div>
	);
}

export default Component;