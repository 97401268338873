import React from 'react';

export default function({label,name,type,required,options,selectMultiple,size}) {

	const random_number = Math.round(Math.random()*100000);
	const random_id		=	type + name + random_number;

	const options_html	=	options.map(o=>{
		if( !o.isGroup )	return createOption(o);
		return createOptionGroup(o);
	});

	const disabled_option_label	= selectMultiple ? '👇 Select one or many...' : 'Pick one...' ;

	return (
	<div className="input-group mb-3">
		<div className="input-group-prepend">
			<label className="input-group-text" htmlFor={random_id}>{label}</label>
		</div>
		<select
			name={name}
			defaultValue=''
			className="custom-select"
			id={random_id}
			required={required}
			multiple={selectMultiple}
			size={size}
		>
			<option disabled value="">{disabled_option_label}</option>
			{options_html}
		</select>
	</div>
	);
}

function createOption(option_data){
	return <option key={option_data.value} value={option_data.value}>{option_data.text}</option>;
}

function createOptionGroup(option_group_data){
	const children	=	option_group_data.options.map(o=>createOption(o));
	return <optgroup label={option_group_data.label} key={option_group_data.label}>{children}</optgroup>;
}